.MuiAccordionSummary-content{
    margin:  0 !important;
}

.MuiButtonBase-root{
    min-height: 0 !important;
}

.HeaderBoxShadow{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}


