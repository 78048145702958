.circle-box-shadow{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-size: cover;
    background: #fff;
}
.circle-box-shadow-plus{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
}
