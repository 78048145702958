.offscreen {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  
  .a4-container {
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    background-color: white;
    /* padding: 20px;  */
    box-sizing: border-box;
    border: 1px solid #ccc; /* Optional: border for visual reference */
    margin: 20px auto; /* Center the container */
  }
  



  /* header */

  .header-box {
    border-bottom: 2px solid rgb(240, 240, 240);
    height: 120px;
  }

  .border-right {
    border-right: 2px solid rgb(240, 240, 240);
    height: 100%;
  }

  .custom-border-bottom {
    border-bottom: 2px solid #000;
    /* height: 100%; */
  }

  .header-box-1{
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .header-box-2{
    flex-grow: 1;
  }
  .header-box-3{
    width: 180px;
  }